import { useTranslation } from "react-i18next";
import { ITeam } from "~/utils/api.interfaces.enums";
import { ButtonModalShareTeamPage } from "~/components/ButtonShareTeamPage";
import Flag from "react-flagpack";
import React from "react";

export default function TeamInfo({
  activeTeam,
  hasAdminRights,
  environment,
}: {
  activeTeam?: ITeam;
  hasAdminRights?: boolean;
  environment?: string;
}) {
  const { t, ready, i18n } = useTranslation("translation");

  return (
    <>
      <div className="flex items-center z-[50] pb-3 justify-start gap-2 xl:gap-6 lg:mb-4 xl:mb-14 flex-col lg:flex-row p-2 lg-p-4 rounded-lg w-full">
        <div className="avatar w-24 h-24 bg-gray-50 rounded-full flex justify-center items-center">
          {activeTeam?.logoUrl ? (
            <img
              src={activeTeam?.logoUrl}
              alt={activeTeam?.fullName}
              className="max-w-24 max-h-24  rounded-full "
            />
          ) : (
            <div className="avatar placeholder w-12 h-12 lg:w-20 lg:h-20 rounded-full object-cover mt-4">
              <div className="bg-neutral-focus text-neutral-content rounded-full lg:w-24">
                <span className="text-md">{activeTeam?.nameAbbreviation}</span>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col mr-auto text-base">
          <h1 className="text-white font-[700] text-[28px] mb-2">
            {activeTeam?.name}
          </h1>
          <div className="flex lg:justify-start items-center gap-4 flex-wrap">
            {/*{activeTeam?.visibilityLevel && (*/}
            {/*  <div className="badge bg-gray-500 py-3 text-white border-0 capitalize flex gap-2">*/}
            {/*    {activeTeam?.visibilityLevel === ITeamVisibilityEnum.visible ? (*/}
            {/*      <svg*/}
            {/*        xmlns="http://www.w3.org/2000/svg"*/}
            {/*        width="23"*/}
            {/*        height="16"*/}
            {/*        fill="none"*/}
            {/*      >*/}
            {/*        <path*/}
            {/*          fill="currentColor"*/}
            {/*          fillRule="evenodd"*/}
            {/*          d="M11.286 2c-2.441 0-4.74 1.138-6.471 2.617-.854.727-1.523 1.497-1.973 2.18-.23.354-.375.642-.46.86a1.836 1.836 0 0 0-.082.256c-.013.056-.014.084-.014.087a.57.57 0 0 0 .014.09c.015.065.04.151.083.26.084.218.228.504.455.845a11.03 11.03 0 0 0 1.975 2.187C6.549 12.85 8.86 14 11.286 14c2.395 0 4.674-1.236 6.44-2.799.865-.776 1.556-1.583 2.014-2.253l.005-.007c.222-.32.373-.597.461-.803l.005-.01.004-.009c.02-.045.036-.087.048-.123a2.022 2.022 0 0 0-.048-.115l-.001-.002a5.985 5.985 0 0 0-.477-.83c-.457-.67-1.148-1.476-2.01-2.25C15.959 3.234 13.694 2 11.286 2Zm10 6.014.993.118.007-.059v-.087c0-.261-.09-.58-.25-.93h.001l-.911.412.91-.412a7.983 7.983 0 0 0-.64-1.125l-.005-.007c-.555-.813-1.356-1.74-2.33-2.616l-.006-.005C17.09 1.563 14.369 0 11.285 0 8.234 0 5.492 1.41 3.518 3.095c-.986.84-1.784 1.75-2.347 2.605l-.002.004C.615 6.553.286 7.356.286 8c0 .641.325 1.457.886 2.303l.003.004a13.021 13.021 0 0 0 2.342 2.598l.003.002C5.49 14.575 8.244 16 11.286 16c3.07 0 5.803-1.563 7.769-3.303l.005-.005c.974-.874 1.774-1.8 2.329-2.613a6.7 6.7 0 0 0 .652-1.143 3.201 3.201 0 0 0 .223-.706l.01-.06.002-.022.002-.008v-.008l-.992-.118Z"*/}
            {/*          clipRule="evenodd"*/}
            {/*        />*/}
            {/*        <path*/}
            {/*          fill="currentColor"*/}
            {/*          fillRule="evenodd"*/}
            {/*          d="M11.286 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"*/}
            {/*          clipRule="evenodd"*/}
            {/*        />*/}
            {/*      </svg>*/}
            {/*    ) : (*/}
            {/*      <svg*/}
            {/*        xmlns="http://www.w3.org/2000/svg"*/}
            {/*        width="23"*/}
            {/*        height="18"*/}
            {/*        fill="none"*/}
            {/*      >*/}
            {/*        <path*/}
            {/*          fill="currentColor"*/}
            {/*          fillRule="evenodd"*/}
            {/*          d="M19.872 6.17a1 1 0 0 1 1.387.273c.275.411.772 1.283.92 1.613.16.35.25.669.25.93v.028c0 .04-.003.079-.008.118l-.993-.118.993.118v.008l-.002.008-.003.022a2.597 2.597 0 0 1-.048.236 3.2 3.2 0 0 1-.185.53c-.15.35-.37.74-.651 1.143-.555.813-1.355 1.74-2.329 2.613l-.005.005c-1.966 1.74-4.7 3.303-7.77 3.303a10.27 10.27 0 0 1-2.483-.311 1 1 0 1 1 .483-1.941 8.273 8.273 0 0 0 2 .252c2.396 0 4.675-1.236 6.442-2.799.864-.776 1.555-1.583 2.013-2.253l.005-.007c.222-.32.373-.597.461-.803l.009-.019c.02-.045.036-.087.048-.123a1.885 1.885 0 0 0-.048-.115l-.001-.002a15.251 15.251 0 0 0-.76-1.322 1 1 0 0 1 .275-1.388ZM11.428 3c-2.44 0-4.739 1.138-6.47 2.617-.854.727-1.523 1.497-1.973 2.18-.23.354-.375.643-.46.86a1.836 1.836 0 0 0-.082.256c-.013.056-.014.084-.015.087 0 .003.002.033.015.09.015.065.04.151.082.26.085.218.23.504.456.845.455.68 1.126 1.463 1.975 2.187.274.232.564.457.868.671a1 1 0 0 1-1.153 1.635c-.353-.25-.69-.511-1.008-.78l-.003-.003a13.021 13.021 0 0 1-2.342-2.598l-.003-.004C.755 10.457.428 9.64.428 9c0-.644.33-1.447.883-2.296l.002-.004c.563-.856 1.361-1.765 2.347-2.605C5.634 2.41 8.376 1 11.428 1c1.124 0 2.199.208 3.204.555 1.242.428 2.381 1.068 3.385 1.798a1 1 0 0 1-1.177 1.618c-.875-.637-1.84-1.174-2.86-1.526A7.814 7.814 0 0 0 11.428 3Z"*/}
            {/*          clipRule="evenodd"*/}
            {/*        />*/}
            {/*        <path*/}
            {/*          fill="currentColor"*/}
            {/*          fillRule="evenodd"*/}
            {/*          d="M22.209.375a1 1 0 0 1-.155 1.406l-14 11.213-6.002 4.788a1 1 0 1 1-1.247-1.564l5.998-4.785 14-11.213a1 1 0 0 1 1.406.155Z"*/}
            {/*          clipRule="evenodd"*/}
            {/*        />*/}
            {/*        <path*/}
            {/*          fill="currentColor"*/}
            {/*          fillRule="evenodd"*/}
            {/*          d="M11.428 7a2 2 0 0 0-1.49 3.333l-1.49 1.334a4 4 0 1 1 6.07-5.21L12.971 7.73A1.994 1.994 0 0 0 11.428 7Z"*/}
            {/*          clipRule="evenodd"*/}
            {/*        />*/}
            {/*      </svg>*/}
            {/*    )}*/}
            {/*    {activeTeam?.visibilityLevel}*/}
            {/*  </div>*/}
            {/*)}*/}
            {/*{!activeTeam?.isArchived && (*/}
            {/*  <>*/}
            {/*    {" "}*/}
            {/*    {activeTeam?.boostStatus?.boostLevel > 0 ? (*/}
            {/*      <div className="badge text-xs bg-black py-2 text-[#FAC99E] border-[#FAC99E]">*/}
            {/*        <svg*/}
            {/*          xmlns="http://www.w3.org/2000/svg"*/}
            {/*          width="18"*/}
            {/*          height="18"*/}
            {/*          fill="none"*/}
            {/*        >*/}
            {/*          <path*/}
            {/*            fill="#FAC99E"*/}
            {/*            fillRule="evenodd"*/}
            {/*            d="M10.342 1.857c.537-.54 1.439-.026 1.255.714l-1.09 4.382h3.056c.65 0 .991.778.551 1.26l-7.206 7.905c-.517.567-1.448.08-1.282-.672l1.122-5.073h-3.07c-.666 0-1-.81-.529-1.285l7.193-7.231Z"*/}
            {/*            clipRule="evenodd"*/}
            {/*          />*/}
            {/*        </svg>*/}
            {/*        level {activeTeam?.boostStatus?.boostLevel}*/}
            {/*      </div>*/}
            {/*    ) : (*/}
            {/*      <div className="badge text-xs  bg-white/10 py-2 text-white border-transparent">*/}
            {/*        <svg*/}
            {/*          xmlns="http://www.w3.org/2000/svg"*/}
            {/*          width="18"*/}
            {/*          height="18"*/}
            {/*          fill="none"*/}
            {/*        >*/}
            {/*          <path*/}
            {/*            fill="#974DDE"*/}
            {/*            fillRule="evenodd"*/}
            {/*            d="M10.342 1.857c.537-.54 1.439-.026 1.255.714l-1.09 4.382h3.056c.65 0 .991.778.551 1.26l-7.206 7.905c-.517.567-1.448.08-1.282-.672l1.122-5.073h-3.07c-.666 0-1-.81-.529-1.285l7.193-7.231Z"*/}
            {/*            clipRule="evenodd"*/}
            {/*          />*/}
            {/*        </svg>*/}
            {/*        level 0*/}
            {/*      </div>*/}
            {/*    )}*/}
            {/*  </>*/}
            {/*)}*/}
            {activeTeam?.isArchived && (
              <div className="badge badge-sm py-2  badge-outline text-gray-400">
                {" "}
                archived
              </div>
            )}

            {activeTeam?.organization?.type !== "default" && (
              <p className="text-white">{activeTeam?.organization?.name} </p>
            )}
            <div className="flex justify-center items-center gap-2">
              {activeTeam?.league?.countryCode && (
                <Flag
                  code={activeTeam?.league?.countryCode.toUpperCase()}
                  hasBorder={false}
                  size="m"
                />
              )}

              {activeTeam?.address ? (
                <>
                  {" "}
                  <p className="text-white">{activeTeam?.address.city} </p>
                  <p className="text-white">{activeTeam?.address.country}</p>
                </>
              ) : (
                <>
                  <p className="text-white">
                    {activeTeam?.organization?.address?.city}
                  </p>
                  <p className="text-white">
                    {" "}
                    {activeTeam?.organization?.address?.country}
                  </p>
                </>
              )}
            </div>

            {activeTeam?.organization?.type === "club" && (
              <>
                <div className="flex justify-center items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="21"
                    fill="none"
                  >
                    <g clipPath="url(#a)">
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="m11.418 1.339 6.076 2.493a1.636 1.636 0 0 1 1.011 1.523V9.5c0 .03-.001.061-.005.092-.157 1.456-.602 3.9-1.956 5.994-1.158 1.787-2.944 2.935-4.19 3.736-.235.15-.45.289-.638.417-.524.36-1.23.402-1.797.054l-.003-.002-.18-.11c-1.28-.792-3.359-2.074-4.665-4.095-1.357-2.099-1.8-4.704-1.956-5.983A.855.855 0 0 1 3.11 9.5V5.355c0-.644.38-1.267 1.011-1.523l6.077-2.493a1.563 1.563 0 0 1 1.22 0Zm-.61 1.599L4.82 5.395v4.053c.154 1.236.566 3.476 1.687 5.21 1.092 1.687 2.875 2.791 4.192 3.606l.075.047c.235-.16.482-.32.735-.484 1.25-.81 2.661-1.724 3.598-3.17 1.127-1.743 1.537-3.844 1.688-5.204V5.395l-5.988-2.457Z"
                        clipRule="evenodd"
                      />
                    </g>
                    <defs>
                      <clipPath id="a">
                        <path fill="#fff" d="M.543.36h20.529v20.528H.543z" />
                      </clipPath>
                    </defs>
                  </svg>

                  <p className="text-white">{activeTeam.league?.name}</p>
                </div>
                <div className="flex justify-center items-center gap-2 text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    // className="w-5 h-5 "
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M11 2a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM0 11C0 4.925 4.925 0 11 0s11 4.925 11 11-4.925 11-11 11S0 17.075 0 11Z"
                      clipRule="evenodd"
                    />
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="m11.15 2.65.37-1.846 1.96.392-.5 2.5a1 1 0 0 1-.784.785l-2.5.5a1 1 0 0 1-.977-.356l-2-2.5L8.281.875 9.9 2.9l1.25-.25Zm5.565 3.432 1.566-1.957-1.562-1.25-2 2.5a1 1 0 0 0-.076 1.14l1.5 2.5a1 1 0 0 0 .998.475l3.5-.5-.282-1.98-2.843.406-.8-1.334ZM14.72 13.875a1 1 0 0 1 1.227-.27l4 2-.894 1.79-3.277-1.639-1.095 1.37 1.213 2.427-1.788.894-1.5-3a1 1 0 0 1 .113-1.072l2-2.5ZM3.4 13.7a1 1 0 0 1 1.115-.057l2.5 1.5a1 1 0 0 1 .471 1.021l-.5 3-1.972-.328.388-2.329-1.34-.804L2.6 16.8l-1.2-1.6 2-1.5ZM2.5 7.22V4.5h2V8a1 1 0 0 1-.757.97l-2 .5-.486-1.94L2.5 7.22ZM8.31 9.33l-.168 1.505 1.463.731 1.07-1.07-.507-1.353-1.859.186ZM7.304 7.42a1 1 0 0 0-.894.884l-.334 3.003a1 1 0 0 0 .547 1.005l2.731 1.366a1 1 0 0 0 1.155-.188l2.035-2.035a1 1 0 0 0 .229-1.058L11.77 7.72a1 1 0 0 0-1.036-.643l-3.429.342Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="text-white">
                    {" "}
                    {t("settingsPage.labelSeasonSingle")}{" "}
                    {activeTeam?.season?.name}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="hidden lg:flex gap-4 flex-wrap">
          {/*{activeTeam?.season.isActive && (*/}
          {/*  <a*/}
          {/*    href={`${*/}
          {/*      process.env.NODE_ENV === "production"*/}
          {/*        ? "https://replay.mingle.sport"*/}
          {/*        : "https://development.replay-mingle-sport.pages.dev"*/}
          {/*    }/${activeTeam?.id}`}*/}
          {/*    className={`btn btn-secondary btn-outline flex-nowrap justify-between items-center ml-auto `}*/}
          {/*    target="_blank"*/}
          {/*  >*/}
          {/*    <div className="w-full max-w-[60px]">*/}
          {/*      <img src="/images/replay2024.svg" alt="" />*/}
          {/*    </div>*/}

          {/*    <svg*/}
          {/*      className="fill-current -rotate-90  w-6 h-6"*/}
          {/*      xmlns="http://www.w3.org/2000/svg"*/}
          {/*      viewBox="0 0 24 24"*/}
          {/*    >*/}
          {/*      <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />*/}
          {/*    </svg>*/}
          {/*  </a>*/}
          {/*)}*/}
          <ButtonModalShareTeamPage
            inHeader={false}
            activeTeam={activeTeam}
            ENVIRONMENT={environment}
          />
        </div>
      </div>
    </>
  );
}
